import * as React from "react";

import { Alert, Snackbar, Typography } from "@mui/material";

import { ExtendedAccount } from "../../../types/ExtendedAccount";
import {
  AccountDetails,
  AccountNextStep,
  AccountType,
  CompanyType,
  YesNo,
} from "@customTypes/index";
import { cleanedErrors } from "@utils/Functions";
import { BooleanInput } from "../../../components/atoms/BooleanInput";
import { SelectInput } from "../../../components/atoms/SelectInput";
import { TextInput } from "../../../components/atoms/TextInput";
import { FormField } from "../../../components/molecules/FormField";
import {
  BankAccountDetailsFields,
  errorsOf as errorsOfBankAccountDetailsFields,
} from "./BankAccountDetailsFields";
import { ServiceProvider } from "./CompanyNameInput";
import { useOnChange, useOnValueChange, useUpdater } from "@utils/callbacks";
import {
  Errors as ResponsibleErrors,
  errorsOf as errorsOfResponsible,
  Person,
  Record as ResponsibleRecord,
  recordFromAccount as responsibleRecordFromAccount,
  ResponsibleFields,
} from "./ResponsibleFields";
import { Record as ServiceProviderRecord } from "./ServiceProviderFields";
import { FormStack } from "../../../components/molecules/FormStack";
import {
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord,
  DeceasedDetailsFields,
} from "./DeceasedDetailsFields";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly accountNumber?: string;
  readonly sortCode?: string;
  readonly holderNumber?: string;
  readonly accountType?: string;
  readonly accountTypeOther?: string;
  readonly needsTaxCertificate?: boolean;
  readonly jointClaim?: string;
  readonly responsible?: ResponsibleRecord;
};

export type Errors =
  | undefined
  | {
    readonly deceased?: DeceasedErrors;
    readonly accountNumber?: string;
    readonly sortCode?: string;
    readonly holderNumber?: string;
    readonly accountType?: string;
    readonly accountTypeOther?: string;
    readonly needsTaxCertificate?: string;
    readonly jointClaim?: string;
    readonly responsible?: ResponsibleErrors;
  };

const accountRequest = AccountNextStep.RequestAccountBalance;

export const errorsOf: (
  r: Record,
  askForDeceasedDetails: boolean,
  askForDeceasedPostcode: boolean
) => Errors = (record, askForDeceasedDetails, askForDeceasedPostcode) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails
      ? undefined
      : errorsOfDeceased(record.deceased, askForDeceasedPostcode),
    accountNumber: !record.accountNumber ? "required" : undefined,
    sortCode: !record.sortCode ? "required" : undefined,
    accountType: record?.accountType ? undefined : "required",
    accountTypeOther:
      record?.accountType !== "other" || record?.accountTypeOther ? undefined : "required",
    responsible: errorsOfResponsible(record?.responsible, accountRequest),
    ...(!record?.accountNumber && !record?.sortCode
      ? {}
      : errorsOfBankAccountDetailsFields(record)),
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {},
      responsible: responsibleRecordFromAccount(account),
    };
  }

  return {
    deceased: {},
    accountNumber: account.accountNumber,
    sortCode: account.sortCode,
    holderNumber: account.holderNumber,
    accountType: account.accountType,
    accountTypeOther: account.accountTypeOther,
    needsTaxCertificate: account.needsTaxCertificate,
    jointClaim: account.jointClaim,
    responsible: responsibleRecordFromAccount(account),
  };
};

export type BankingAccountsFieldsProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly serviceProvider: ServiceProviderRecord;
  readonly account?: AccountDetails;
  readonly setBusy: (b: boolean) => void;
  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: { newServiceProvider?: ServiceProvider; newPerson?: Person }
  ) => void;
  readonly updateTemplate?: (p: any) => void;

  readonly saveAccount: (r: { sector: CompanyType; record: any }) => Promise<any>;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly askForDeceasedDetails?: boolean;
  readonly askForDeceasedPostcode?: boolean;
};

export const BankingAccountFields: React.FC<BankingAccountsFieldsProps> = ({
  persons,
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false,
  askForDeceasedPostcode = false,
}) => {
  const [record, update] = React.useState(savedRecord || recordFromAccount(account));
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateAccountHolder = useUpdater(update, "responsible");
  const updateDeceasedFields = useUpdater(update, "deceased");

  const onChange = useOnChange(update);
  const onAccountTypeValueChange = useOnValueChange(update, "accountType");
  const onNeedsTaxCertificateValueChange = useOnValueChange(update, "needsTaxCertificate");

  const onJointClaimValueChanged = React.useCallback(
    (jointClaim) => {
      update((s: any) => ({
        ...s,
        jointClaim: jointClaim ? YesNo.Yes : YesNo.No,
      }));
    },
    [update]
  );

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      // onNextLabel: "Continue",
      onNext: !serviceProvider?.companyType
        ? undefined
        : () => {
          const errors = errorsOf(record, askForDeceasedDetails, askForDeceasedPostcode);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          const responsible = {
            id: record.responsible?.responsibleId || undefined,
            details: record.responsible?.responsible || undefined,
            bankAccount: record.responsible?.responsibleBankAccount || undefined,
          };

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              companyType: serviceProvider.companyType,
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            accountNumber: record.accountNumber,
            sortCode: record.sortCode,
            holderNumber: record.holderNumber,
            accountType: record.accountType,
            accountTypeOther: record.accountTypeOther,
            needsTaxCertificate: record.needsTaxCertificate,
            jointClaim: record.jointClaim,
            nextStep: accountRequest,
            responsible,
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          setBusy(true);

          return saveAccount({
            sector: CompanyType.Banking,
            record: data,
          }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson,
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        },
    });
  }, [saveAccount, updateTemplate, serviceProvider, account, onAccountAdded, record, setBusy]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          askForPostcode={askForDeceasedPostcode}
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <BankAccountDetailsFields
        record={record}
        update={update}
        errors={errors}
        required={serviceProvider.customForm === "starling-bank"}
      />

      <FormField halfWidth label="Account type">
        <SelectInput
          name="accountType"
          value={record.accountType || ""}
          onValueChange={onAccountTypeValueChange}
          error={errors?.accountType}
          options={
            serviceProvider.customForm === "starling-bank"
              ? starlingBankaccountTypeOptions
              : accountTypeOptions
          }
        />

        {record.accountType === "other" && (
          <FormStack substack spacing={1}>
            <FormField label="Please provide the account type">
              <TextInput
                name={"accountTypeOther"}
                value={record.accountTypeOther || ""}
                error={errors?.accountTypeOther}
                onChange={onChange}
              />
            </FormField>
          </FormStack>
        )}
      </FormField>

      <FormField
        halfWidth
        label={
          serviceProvider.customForm === "monzo"
            ? "Customer email or phone number"
            : "Customer reference"
        }
      >
        <TextInput
          name="holderNumber"
          value={record.holderNumber || ""}
          onChange={onChange}
          error={errors?.holderNumber}
        />
      </FormField>

      <FormField>
        <BooleanInput
          label="This is a joint account"
          value={record.jointClaim === YesNo.Yes}
          onValueChange={onJointClaimValueChanged}
        />
      </FormField>

      <FormField>
        <BooleanInput
          label="Needs interest or tax certificate"
          value={!!record.needsTaxCertificate}
          onValueChange={onNeedsTaxCertificateValueChange}
        />
      </FormField>

      {record.responsible && (
        <ResponsibleFields
          serviceProvider={serviceProvider}
          persons={persons}
          nextStep={accountRequest}
          record={record.responsible}
          errors={errors?.responsible}
          update={updateAccountHolder}
        />
      )}

      <FormField>
        <Typography variant="body1">
          We will obtain Date of Death Balances and details of any Interest Accrued.
        </Typography>
      </FormField>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};

const accountTypeOptions = [
  { value: AccountType.CurrentAccount, label: "Current account" },
  { value: AccountType.SavingsAccount, label: "Savings account" },
  { value: AccountType.CashISA, label: "Cash ISA" },
  { value: AccountType.Other, label: "Other" },
  { value: AccountType.Unknown, label: "Unknown" },
];

const starlingBankaccountTypeOptions = [
  { value: AccountType.Personal, label: "Personal account" },
  { value: AccountType.Business, label: "Business account" },
  { value: AccountType.PersonalAndBusiness, label: "Both" },
];
