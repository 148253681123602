import * as React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Grid, Stack } from "@mui/material";

import { createDocumentAndUpload, getDocument, getCase } from "@api/caseApi";
import urlPaths from "../../urlPaths";
import { BackLink } from "../../components/atoms/BackLink";
import { Header } from "../../components/atoms/Header";
import { NeedHelpBox2 } from "../../components/molecules/NeedHelpBox";
import { SimpleTemplate } from "@templates/SimpleTemplate";
import { ServiceProvider } from "../NotifierForm/AccountForm/CompanyNameInput";
import { Property } from "../NotifierForm/AccountForm/PropertyFields";
import { Person } from "../NotifierForm/AccountForm/ResponsibleFields";
import { AccountForm } from "../NotifierForm/AccountForm";
import { Button } from "../../components/atoms/Button";

export const NewAccountPage: React.FC<{
  caseId: string;
  signature: string | null;
  userIsAdmin?: boolean;
}> = ({ caseId, signature, userIsAdmin }) => {
  const navigate = useNavigate();

  const [{ persons, properties, serviceProviders }, setState] = React.useState({
    serviceProviders: [] as ReadonlyArray<ServiceProvider>,
    properties: [] as ReadonlyArray<Property>,
    persons: [] as ReadonlyArray<Person>,
  });

  const [remoteError, setRemoteError] = React.useState<string | undefined>();

  const [busy, setBusy] = React.useState(false);

  const onAccountAdded = React.useCallback(() => {
    setBusy(false);
    if (userIsAdmin) {
      window.close();
    } else {
      navigate(`/status`, { replace: true });
    }
  }, [setBusy, navigate, userIsAdmin]);

  const closeWindow = React.useCallback(() => {
    window.close();
  }, []);

  // TODO: Refactor this repeated code
  const uploadedFileInfo = React.useCallback(
    (documentId: string) => {
      return getDocument({ caseId, signature, documentId })
        .then((res: any) => {
          if (!res.data) {
            throw new Error(`Missing results for document ${documentId}`);
          }

          return res.data;
        })
        .catch((err: Error) => {
          console.warn(err.message);
          setRemoteError("Failed to download the file you previously uploaded.");
          throw err;
        });
    },
    [caseId, signature]
  );

  React.useEffect(() => {
    getCase({ caseId, signature })
      .then((res) => {
        if (!res.data?.case) {
          return Promise.reject(new Error("Missing data."));
        }

        let { case: caseRecord, persons, properties, serviceProviders } = res.data;

        if (!caseRecord?.formSubmittedAt) {
          navigate(urlPaths.root(), { replace: true });
          return;
        }

        setState({
          persons,
          properties,
          serviceProviders,
        });
      })
      .catch((err) => {
        setState((s) => ({ ...s }));
        console.warn({ err });
      });
  }, [caseId, signature, navigate]);

  return (
    <NewAccountPageView
      caseId={caseId}
      signature={signature}
      userIsAdmin={userIsAdmin}
      serviceProviders={serviceProviders}
      persons={persons}
      properties={properties}
      busy={busy}
      setBusy={setBusy}
      closeWindow={closeWindow}
      onAccountAdded={onAccountAdded}
      uploadedFileInfo={uploadedFileInfo}
      errorMessage={remoteError}
    />
  );
};

export type NewAccountPageViewProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly userIsAdmin?: boolean;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly properties: ReadonlyArray<Property>;
  readonly persons: ReadonlyArray<Person>;
  readonly busy: boolean;
  readonly setBusy: (busy: boolean) => void;
  readonly closeWindow: () => void;
  readonly onAccountAdded: () => void;
  readonly uploadedFileInfo: (documentId: string) => Promise<any>;
  readonly errorMessage?: string;
};

export const NewAccountPageView: React.FC<NewAccountPageViewProps> = ({
  caseId,
  signature,
  userIsAdmin,
  closeWindow,
  serviceProviders,
  properties,
  persons,
  busy,
  setBusy,
  onAccountAdded,
  uploadedFileInfo,
  errorMessage,
}) => {
  const { onNext, update: updateTemplate } = useActions();

  return (
    <SimpleTemplate busy={busy} headerType="signout" errorMessage={errorMessage}>
      <Stack rowGap={4}>
        {userIsAdmin ? (
          <BackLink onClick={closeWindow}>Cancel and close this window</BackLink>
        ) : (
          <BackLink to="/status">Back to dashboard</BackLink>
        )}

        <Stack rowGap={2}>
          <Header level={1}>Add a new account</Header>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={8}>
              <Stack rowGap={4}>
                <AccountForm
                  caseId={caseId}
                  signature={signature}
                  serviceProviders={serviceProviders || []}
                  properties={properties}
                  persons={persons}
                  setBusy={setBusy}
                  onAccountUpdated={onAccountAdded}
                  uploadedFileInfo={uploadedFileInfo}
                  createDocumentAndUpload={(...args) =>
                    createDocumentAndUpload(caseId, signature, ...args)
                  }
                  updateTemplate={updateTemplate}
                />

                <Box>
                  <Button
                    variant="secondary"
                    size="large"
                    disabled={!onNext || busy}
                    onClick={onNext}
                  >
                    Add account
                  </Button>
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={4}>
              <NeedHelpBox2 />
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </SimpleTemplate>
  );
};

type State = {
  onNext?: () => void;
};

const useActions = () => {
  const [{ onNext }, update] = React.useState<State>({});

  return {
    onNext,
    update,
  };
};
