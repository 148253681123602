import * as React from "react";

import { Alert, Snackbar } from "@mui/material";

import { Document } from "../../../types/Document";
import { ExtendedAccount } from "../../../types/ExtendedAccount";
import { AccountDetails, AccountNextStep, CompanyType } from "@customTypes/index";
import { cleanedErrors } from "@utils/Functions";
import { TextInput } from "../../../components/atoms/TextInput";
import { FormField } from "../../../components/molecules/FormField";
import { ServiceProvider } from "./CompanyNameInput";
import { useOnChange, useUpdater } from "@utils/callbacks";
import { Record as ServiceProviderRecord } from "./ServiceProviderFields";
import { Person } from "./ResponsibleFields";
import {
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord,
  DeceasedDetailsFields,
} from "./DeceasedDetailsFields";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly emailAddress?: string;
  readonly phoneNumber?: string;
  readonly profileUrl?: string;
};

export type Errors =
  | undefined
  | {
    readonly deceased?: DeceasedErrors;
    readonly emailAddress?: string;
    readonly phoneNumber?: string;
    readonly profileUrl?: string;
  };

const accountRequest = AccountNextStep.Cancel;

export const errorsOf: (r: Record, askForDeceasedDetails: boolean) => Errors = (
  record,
  askForDeceasedDetails
) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    emailAddress: record?.emailAddress ? undefined : "required",
    // phoneNumber: record?.phoneNumber ? undefined : "required",
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {},
    };
  }

  return {
    deceased: {},
    emailAddress: account.emailAddress,
    phoneNumber: account.phoneNumber,
    profileUrl: account.profileUrl,
  };
};

export type SocialMediaOrEmailAccountFieldsProps = {
  readonly persons: ReadonlyArray<Person>;
  readonly serviceProvider: ServiceProviderRecord;
  readonly account?: AccountDetails;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: { newServiceProvider?: ServiceProvider; newPerson?: Person }
  ) => void;
  readonly updateTemplate?: (p: any) => void;
  readonly initialRecord?: Record;

  readonly saveAccount: (r: { sector: CompanyType; record: any }) => Promise<any>;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly askForDeceasedDetails?: boolean;
};

export const SocialMediaOrEmailAccountFields: React.FC<SocialMediaOrEmailAccountFieldsProps> = ({
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  initialRecord,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false,
}) => {
  const [record, update] = React.useState(
    savedRecord || (initialRecord && !account ? initialRecord : recordFromAccount(account))
  );
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateDeceasedFields = useUpdater(update, "deceased");
  const onChange = useOnChange(update);

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      onNext: !serviceProvider?.companyType
        ? undefined
        : () => {
          const errors = errorsOf(record, askForDeceasedDetails);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              companyType: serviceProvider.companyType,
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            emailAddress: record.emailAddress,
            phoneNumber: record.phoneNumber,
            profileUrl: record.profileUrl,
            nextStep: accountRequest,
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          setBusy(true);

          return saveAccount({
            sector: CompanyType.SocialMedia,
            record: data,
          }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson,
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        },
    });
  }, [updateTemplate, serviceProvider, account, onAccountAdded, record, setBusy, saveAccount]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <FormField halfWidth label="Associated email address">
        <TextInput
          name={"emailAddress"}
          value={record.emailAddress || ""}
          error={errors?.emailAddress}
          onChange={onChange}
        />
      </FormField>

      {serviceProvider.companyType !== CompanyType.Email && (
        <>
          <FormField halfWidth label="Associated phone number">
            <TextInput
              name={"phoneNumber"}
              value={record.phoneNumber || ""}
              error={errors?.phoneNumber}
              onChange={onChange}
            />
          </FormField>

          <FormField twoThirdsWidthByItself label="Associated profile link (if available)">
            <TextInput
              name={"profileUrl"}
              value={record.profileUrl || ""}
              error={errors?.profileUrl}
              onChange={onChange}
            />
          </FormField>
        </>
      )}

      <FormField>We will assist you in closing this account.</FormField>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};
