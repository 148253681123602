import * as React from "react";

import { Alert, Snackbar, Stack, Typography } from "@mui/material";

import { Document } from "../../../types/Document";
import { ExtendedAccount } from "../../../types/ExtendedAccount";
import {
  AccountDetails,
  AccountNextStep,
  AccountType,
  CompanyType,
  YesNo,
} from "@customTypes/index";
import { cleanedErrors } from "@utils/Functions";
import { RadioGroupInput } from "../../../components/atoms/RadioGroupInput";
import { SelectInput } from "../../../components/atoms/SelectInput";
import { TextInput } from "../../../components/atoms/TextInput";
import { FormField } from "../../../components/molecules/FormField";
import { ServiceProvider } from "./CompanyNameInput";
import {
  DeceasedDetailsFields,
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord,
} from "./DeceasedDetailsFields";
import { useOnChange, useOnValueChange, useUpdater } from "@utils/callbacks";
import {
  Errors as PropertyErrors,
  errorsOfAddressOnly as errorsOfProperty,
  Property,
  PropertyFields,
  propertyFieldsFromRecordAddressOnly,
  Record as PropertyFieldRecord,
  recordFromAccount as propertyRecordFromAccount,
} from "./PropertyFields";
import {
  Errors as RefundFieldsErrors,
  errorsOf as refundFieldsErrorsOf,
  Record as RefundFieldsRecord,
  RefundFields,
} from "./RefundFields";
import {
  Errors as ResponsibleErrors,
  errorsOf as errorsOfResponsible,
  Person,
  Record as ResponsibleRecord,
  recordFromAccount as responsibleRecordFromAccount,
  ResponsibleFields,
} from "./ResponsibleFields";
import { Record as ServiceProviderRecord } from "./ServiceProviderFields";
import { FormStack } from "../../../components/molecules/FormStack";
import { InfoBoxTrigger } from "../../../components/molecules/InfoBoxTrigger";
import { InfoOutlined } from "@mui/icons-material";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly accountNumber?: string;
  readonly accountType?: string;
  readonly accountTypeOther?: string;
  readonly accountName?: string;
  readonly property?: PropertyFieldRecord;
  readonly jointClaim?: string;
  readonly accountRequest?: AccountNextStep;
  readonly responsible?: ResponsibleRecord;
  readonly refund?: RefundFieldsRecord;

  readonly paymentBankAccountHolderName?: string;
  readonly paymentBankAccountNumber?: string;
  readonly paymentBankAccountSortCode?: string;
};

export type Errors =
  | undefined
  | {
      readonly deceased?: DeceasedErrors;
      readonly accountNumber?: string;
      readonly accountType?: string;
      readonly accountTypeOther?: string;
      readonly property?: PropertyErrors;
      readonly jointClaim?: string;
      readonly accountRequest?: string;
      readonly responsible?: ResponsibleErrors;
      readonly refund?: RefundFieldsErrors;

      readonly paymentBankAccountHolderName?: string;
      readonly paymentBankAccountNumber?: string;
      readonly paymentBankAccountSortCode?: string;
    };

export const errorsOf: (
  r: Record,
  askForDeceasedDetails: boolean,
  notifierIsResponsible: boolean
) => Errors = (record, askForDeceasedDetails, notifierIsResponsible) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    accountType: record?.accountType ? undefined : "required",
    accountTypeOther:
      record?.accountType !== "other" || record?.accountTypeOther ? undefined : "required",
    property: errorsOfProperty(record?.property),
    accountRequest: record?.accountRequest ? undefined : "required",
    jointClaim: record?.jointClaim ? undefined : "required",
    responsible:
      record?.jointClaim === YesNo.Yes
        ? errorsOfResponsible(record?.responsible, record?.accountRequest)
        : undefined,
    refund:
      record.accountRequest === AccountNextStep.Cancel
        ? refundFieldsErrorsOf(record.refund, notifierIsResponsible)
        : undefined,
    paymentBankAccountHolderName:
      record?.jointClaim === YesNo.Yes &&
      record.accountRequest === AccountNextStep.UpdateAccountHolder &&
      !record?.paymentBankAccountHolderName
        ? "required"
        : undefined,
    paymentBankAccountNumber:
      record?.jointClaim === YesNo.Yes &&
      record.accountRequest === AccountNextStep.UpdateAccountHolder &&
      !record?.paymentBankAccountNumber
        ? "required"
        : undefined,
    paymentBankAccountSortCode:
      record?.jointClaim === YesNo.Yes &&
      record.accountRequest === AccountNextStep.UpdateAccountHolder &&
      !record?.paymentBankAccountSortCode
        ? "required"
        : undefined,
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {},
      responsible: responsibleRecordFromAccount(account),
    };
  }

  return {
    deceased: {},
    accountNumber: account.accountNumber,
    accountType: account.accountType,
    accountTypeOther: account.accountTypeOther,
    property: propertyRecordFromAccount(account),
    jointClaim: account.jointClaim,
    accountRequest: account.nextSteps,
    responsible: responsibleRecordFromAccount(account),
    refund:
      account.nextSteps === AccountNextStep.Cancel
        ? {
            refundPaymentMethod: account.refundPaymentMethod,
            refundBankAccountHolderName: account.refundBankAccountHolderName,
            refundBankAccountNumber: account.refundBankAccountNumber,
            refundBankAccountSortCode: account.refundBankAccountSortCode,
            refundChequePayableTo: account.refundChequePayableTo,
          }
        : undefined,
    paymentBankAccountHolderName: account.paymentBankAccountHolderName,
    paymentBankAccountNumber: account.paymentBankAccountNumber,
    paymentBankAccountSortCode: account.paymentBankAccountSortCode,
  };
};

export type AIGAccountFieldsProps = {
  readonly properties: ReadonlyArray<Property>;
  readonly persons: ReadonlyArray<Person>;
  readonly serviceProvider: ServiceProviderRecord;
  readonly account?: AccountDetails;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: { newServiceProvider?: ServiceProvider; newPerson?: Person }
  ) => void;
  readonly updateTemplate?: (p: any) => void;

  readonly saveAccount: (r: { sector: CompanyType; record: any }) => Promise<any>;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly askForDeceasedDetails?: boolean;
};

export const AIGAccountFields: React.FC<AIGAccountFieldsProps> = ({
  properties,
  persons,
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false,
}) => {
  const [record, update] = React.useState(savedRecord || recordFromAccount(account));
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateDeceasedFields = useUpdater(update, "deceased");

  const onJointClaimValueChanged = React.useCallback(
    (jointClaim) => {
      update((s: any) => ({
        ...s,
        jointClaim,
        accountRequest: jointClaim === YesNo.No ? AccountNextStep.Cancel : undefined,
        responsible: undefined,
        refund: jointClaim === YesNo.No ? {} : undefined,
        paymentBankAccountHolderName: undefined,
        paymentBankAccountNumber: undefined,
        paymentBankAccountSortCode: undefined,
      }));
    },
    [update]
  );

  const onAccountRequestValueChange = React.useCallback(
    (accountRequest) => {
      update((s: any) => ({
        ...s,
        accountRequest,
        responsible: {},
        refund: accountRequest === AccountNextStep.Cancel ? {} : undefined,
        paymentBankAccountHolderName: undefined,
        paymentBankAccountNumber: undefined,
        paymentBankAccountSortCode: undefined,
      }));
    },
    [update]
  );

  const updateProperty = useUpdater(update, "property");
  const updateAccountHolder = useUpdater(update, "responsible");
  const onAccountTypeValueChange = useOnValueChange(update, "accountType");
  const updateRefund = useUpdater(update, "refund");
  const onChange = useOnChange(update);

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      // onNextLabel: "Continue",
      onNext: !serviceProvider?.companyType
        ? undefined
        : () => {
            const errors = errorsOf(
              record,
              askForDeceasedDetails,
              notifierIsResponsible(record, persons[0].id)
            );

            if (errors) {
              setErrors(errors);
              if (saveRecord) {
                saveRecord({ source: record });
              }
              console.log(errors);
              return;
            }

            const responsible =
              record.jointClaim === YesNo.No
                ? { id: persons[0].id }
                : {
                    id: record.responsible?.responsibleId || undefined,
                    details: record.responsible?.responsible || undefined,
                    bankAccount: record.responsible?.responsibleBankAccount || undefined,
                  };

            const data = {
              id: account?.id,
              serviceProvider: {
                id: serviceProvider.serviceProviderId,
                companyName: serviceProvider.customProviderName,
                companyType: serviceProvider.companyType,
              },
              deceased: askForDeceasedDetails ? record.deceased : undefined,
              accountNumber: record.accountNumber,
              accountType: record.accountType,
              accountTypeOther: record.accountTypeOther,
              accountName: record.accountName,
              ...propertyFieldsFromRecordAddressOnly(record.property),
              vehicleMake: record.vehicleMake,
              vehicleModel: record.vehicleModel,
              vehicleRegistrationNumber: record.vehicleRegistrationNumber,
              nextStep: record.accountRequest,
              // documentIds,
              jointClaim: record.jointClaim,
              responsible,
              refundPaymentMethod: record.refund?.refundPaymentMethod,
              refundBankAccountHolderName: record.refund?.refundBankAccountHolderName,
              refundBankAccountNumber: record.refund?.refundBankAccountNumber,
              refundBankAccountSortCode: record.refund?.refundBankAccountSortCode,
              refundChequePayableTo: record.refund?.refundChequePayableTo,
              paymentBankAccountHolderName: record.paymentBankAccountHolderName,
              paymentBankAccountNumber: record.paymentBankAccountNumber,
              paymentBankAccountSortCode: record.paymentBankAccountSortCode,
            };

            if (saveRecord) {
              saveRecord({ source: record, target: data });
              return { response: {} };
            }

            setBusy(true);

            return saveAccount({
              sector: CompanyType.Insurance,
              record: data,
            }).then(
              (
                response: {
                  data: {
                    account: ExtendedAccount;
                    newServiceProvider?: ServiceProvider;
                    newPerson?: Person;
                  };
                } & { error: Error }
              ) => {
                setBusy(false);
                if (response.error) {
                  setRemoteErrors(response.error.message);
                  return;
                }
                if (response.data) {
                  onAccountAdded(response.data.account, {
                    newServiceProvider: response.data.newServiceProvider,
                    newPerson: response.data.newPerson,
                  });
                  return;
                }
              },
              (err: Error) => {
                console.warn({ err });
                setBusy(false);
                setRemoteErrors("Operation failed. Please try again or contact customer support.");
              }
            );
          },
    });
  }, [
    updateTemplate,
    serviceProvider,
    account,
    onAccountAdded,
    record,
    setBusy,
    saveAccount,
    askForDeceasedDetails,
    saveRecord,
  ]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <FormField
        halfWidth
        label={
          <span>
            Policy number{" "}
            <InfoBoxTrigger
              white
              content={
                <Stack rowGap={2}>
                  <Typography variant="body1">
                    If Policy number is unknown, please note that the reference number listed
                    against the payment for AIG on an insured’s bank statement can be used.
                  </Typography>
                </Stack>
              }
            />
          </span>
        }
      >
        <TextInput
          name={"accountNumber"}
          value={record.accountNumber || ""}
          onChange={onChange}
          error={errors?.accountNumber}
          placeholder="00000000"
        />
      </FormField>

      <FormField
        halfWidth
        label={
          <>
            Policy type
            <span style={{ visibility: "hidden" }}>
              <InfoOutlined fontSize="small" color="secondary" />
            </span>
          </>
        }
      >
        <SelectInput
          name="accountType"
          value={record.accountType || ""}
          onValueChange={onAccountTypeValueChange}
          error={errors?.accountType}
          options={accountTypeOptions}
        />

        {record.accountType === AccountType.Other && (
          <TextInput
            name={"accountTypeOther"}
            value={record.accountTypeOther || ""}
            error={errors?.accountTypeOther}
            onChange={onChange}
          />
        )}
      </FormField>

      <PropertyFields
        serviceProvider={serviceProvider}
        addressOnly
        label="What is the address associated with this policy?"
        serviceProviderId={serviceProvider.serviceProviderId}
        persons={persons}
        properties={properties}
        record={record.property || {}}
        errors={errors?.property}
        update={updateProperty}
      />

      <FormField halfWidth label="This is a:">
        <RadioGroupInput
          fields={jointPolicyOptions}
          value={record.jointClaim}
          onValueChange={onJointClaimValueChanged}
          error={errors?.jointClaim}
        />
      </FormField>

      {record.jointClaim === YesNo.No && (
        <FormField label="Next step">
          We will request for the individual policy to be cancelled.
        </FormField>
      )}

      {record.jointClaim === YesNo.Yes && (
        <FormField label="Next step">
          <RadioGroupInput
            onValueChange={onAccountRequestValueChange}
            value={record.accountRequest || ""}
            fields={nextStepOptions}
            error={errors?.accountRequest}
          />
        </FormField>
      )}

      {record.responsible && record.accountRequest && record.jointClaim === YesNo.Yes && (
        <>
          <ResponsibleFields
            serviceProvider={serviceProvider}
            persons={persons}
            labelOverride={"Who is the surviving joint policy holder?"}
            // do not ask for bank account details
            nextStep={AccountNextStep.RequestAccountBalance}
            record={record.responsible}
            errors={errors?.responsible}
            update={updateAccountHolder}
          />

          {record.accountRequest === AccountNextStep.UpdateAccountHolder && (
            <>
              <FormField>
                <Typography variant="body1">
                  Going forward, from which bank account will the premiums be paid?
                </Typography>
              </FormField>

              <FormStack substack>
                <FormField halfWidthByItself label="Bank account holder name">
                  <TextInput
                    name="paymentBankAccountHolderName"
                    value={record.paymentBankAccountHolderName || ""}
                    onChange={onChange}
                    error={errors?.paymentBankAccountHolderName}
                  />
                </FormField>

                <FormField halfWidth label="Account number">
                  <TextInput
                    name="paymentBankAccountNumber"
                    value={record.paymentBankAccountNumber || ""}
                    onChange={onChange}
                    error={errors?.paymentBankAccountNumber}
                    placeholder="00000000"
                    required={true}
                  />
                </FormField>

                <FormField halfWidth label="Sort code">
                  <TextInput
                    name="paymentBankAccountSortCode"
                    value={record.paymentBankAccountSortCode || ""}
                    onChange={onChange}
                    error={errors?.paymentBankAccountSortCode}
                    placeholder="000000"
                  />
                </FormField>
              </FormStack>
            </>
          )}
        </>
      )}

      {record.refund && (
        <RefundFields
          accountType={record.accountType}
          persons={persons}
          record={record.refund}
          errors={errors?.refund}
          update={updateRefund}
          allowTransferToAnotherBankAccount={!/[A-Za-z]/.test(record.accountNumber || "")}
          notifierIsResponsible={notifierIsResponsible(record, persons[0].id)}
        />
      )}

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};

const notifierIsResponsible = (record: Record, notifierId: string) => {
  return (
    record.jointClaim !== YesNo.Yes ||
    (!record.responsible?.responsible &&
      (!record.responsible || record.responsible.responsibleId === notifierId))
  );
};

const accountTypeOptions = [
  { value: AccountType.PersonalAccidentInsurance, label: "Personal accident insurance" },
  { value: AccountType.PrivateMedicalInsurance, label: "Private medical insurance" },
  { value: AccountType.HospitalCashPlan, label: "Hospital cash plan" },
  { value: AccountType.CancerCover, label: "Cancer cover" },
];

const jointPolicyOptions = [
  { label: "Individual policy", value: YesNo.No },
  { label: "Joint policy", value: YesNo.Yes },
];

const nextStepOptions = [
  { value: AccountNextStep.Cancel, label: "Cancel joint policy" },
  {
    value: AccountNextStep.UpdateAccountHolder,
    label: "Transfer joint policy",
    info: "The policy can only be kept in the name of someone who is already on the existing policy.",
  },
];
