import React from "react";

import { Stack, Typography } from "@mui/material";

import { AccountDetails, CompanyType } from "@customTypes/index";
import {
  CompanyTypeInput,
  findGroupFromCompanyType,
  SectorGroup,
} from "../../../components/molecules/CompanyTypeInput";
import { FormField } from "../../../components/molecules/FormField";
import { InfoBoxTrigger } from "../../../components/molecules/InfoBoxTrigger";
import { CompanyNameInput, ServiceProvider } from "./CompanyNameInput";

export type Record = {
  readonly serviceProviderId?: string;
  readonly group?: SectorGroup;
  readonly companyType?: CompanyType;
  readonly addingServiceProvider?: boolean;
  readonly customProviderName?: string;
  readonly customForm?: string;
};

type Updater<T> = ((_: T) => void) & ((_: (_: T) => T) => void);

export const recordFromAccount = (
  account: AccountDetails | undefined,
  serviceProvider?: ServiceProvider
): Record => {
  if (serviceProvider) {
    return {
      serviceProviderId: serviceProvider.id,
      group: findGroupFromCompanyType(serviceProvider.serviceProviderType) || undefined,
      companyType: serviceProvider.serviceProviderType,
      addingServiceProvider: false,
      customForm: serviceProvider.customForm,
    };
  }

  if (account) {
    return {
      serviceProviderId: account.companyId,
      group: findGroupFromCompanyType(account.companyType) || undefined,
      companyType: account.companyType,
      addingServiceProvider: false,
    };
  }

  return {};
};

export type ServiceProviderFieldsProps = {
  serviceProviders: ReadonlyArray<ServiceProvider>;
  record: Record;
  errors: any;
  update: Updater<Record>;
};

export const ServiceProviderFields: React.FC<ServiceProviderFieldsProps> = ({
  serviceProviders,
  record,
  errors,
  update,
}) => {
  const setNewServiceProviderName = React.useCallback(
    (name) => {
      update(({ group }) => ({
        addingServiceProvider: true,
        group,
        companyType: group?.newSPSector,
        customProviderName: name,
      }));
    },
    [update]
  );

  const setServiceProvider = React.useCallback(
    (serviceProviderId: string, companyType: CompanyType, customForm: string) => {
      update(({ group }) => ({
        addingServiceProvider: false,
        group,
        companyType,
        serviceProviderId,
        customForm,
      }));
    },
    [update]
  );

  const clear = React.useCallback(() => {
    update(({ group }) => ({
      addingServiceProvider: false,
      group,
      companyType: group?.newSPSector,
    }));
  }, [update]);

  const onNewServiceProviderTypeChange = React.useCallback(
    (group) => {
      update({
        addingServiceProvider: false,
        group,
        companyType: group.newSPSector,
      });
    },
    [update]
  );

  return (
    <>
      <FormField>
        <Typography variant="body1">What sector does this account apply to?</Typography>
      </FormField>

      <FormField>
        <CompanyTypeInput value={record.group} onValueChange={onNewServiceProviderTypeChange} />
      </FormField>

      {record.group && (
        <FormField
          label={
            <span>
              Who is the service provider?{" "}
              <InfoBoxTrigger
                white
                content={
                  <Stack rowGap={4}>
                    <Stack rowGap={2}>
                      <Typography variant="body1">
                        <b>My service provider isn’t listed. What should I do?</b>
                      </Typography>

                      <Typography variant="body1">
                        Settld will help you to notify any company, even if they aren’t listed yet.
                        If you can’t find the organisation, please:{" "}
                      </Typography>

                      <ol style={{ marginTop: 0 }}>
                        <li>Enter the name of your service provider in the text box</li>
                        <li>Provide the details for the account</li>
                        <li>We will contact them for you</li>
                      </ol>
                    </Stack>
                  </Stack>
                }
              />
            </span>
          }
        >
          <CompanyNameInput
            serviceProviders={serviceProviders}
            sectors={record.group.sectors}
            serviceProviderId={record.serviceProviderId}
            customProviderName={record.customProviderName}
            setNewServiceProviderName={setNewServiceProviderName}
            setServiceProvider={setServiceProvider}
            clear={clear}
          />
        </FormField>
      )}
    </>
  );
};
