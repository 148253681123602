import * as React from "react";

import { createAccount, updateAccount } from "@api/online-api";
import { Document, UploadRecord } from "@src/types";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { AccountDetails, CompanyType, NotifierFormData } from "@src/types";
import { FormStack } from "@molecules/FormStack";
import { AIGAccountFields } from "./AIGAccountFields";
import { BankingAccountFields } from "./BankingAccountFields";
import { ServiceProvider } from "./CompanyNameInput";
import { CreditCardsAccountFields } from "./CreditCardsAccountFields";
import { DefaultAccountFields } from "./DefaultAccountFields";
import { EnergyAccountFields } from "./EnergyAccountFields";
import { HastingsDirectAccountFields } from "./HastingsDirectAccountFields";
import { InsuranceAccountFields } from "./InsuranceAccountFields";
import { InvestmentsAccountFields } from "./InvestmentsAccountFields";
import { MobileAccountFields } from "./MobileAccountFields";
import { MortgageAccountFields } from "./MortgageAccountFields";
import { PensionAccountFields } from "./PensionAccountFields";
import { Property } from "./PropertyFields";
import { Person } from "./ResponsibleFields";
import {
  recordFromAccount as serviceProviderRecordFromAccount,
  ServiceProviderFields,
} from "./ServiceProviderFields";
import { SocialMediaOrEmailAccountFields } from "./SocialMediaOrEmailAccountFields";
import { WaterAccountFields } from "./WaterAccountFields";

export type AccountFormProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly serviceProvider?: ServiceProvider;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly createDocumentAndUpload: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;
  readonly onAccountUpdated: (
    a: ExtendedAccount,
    p?: {
      newServiceProvider?: ServiceProvider;
      newPerson?: Person;
      newProperty?: Property;
    }
  ) => void;
  readonly account?: AccountDetails;
  readonly updateTemplate?: (p: any) => void;
  readonly form?: NotifierFormData;
  readonly savedRecord?: any;
  readonly saveRecord?: (r: any) => void;
  readonly askForDeceasedDetails?: boolean;
};

export const AccountForm: React.FC<AccountFormProps> = ({
  caseId,
  signature,
  serviceProvider: defaultServiceProvider,
  serviceProviders,
  properties,
  persons,
  setBusy,
  onAccountUpdated,
  account,
  uploadedFileInfo,
  createDocumentAndUpload,
  updateTemplate,
  form,
  savedRecord,
  saveRecord,
  askForDeceasedDetails,
}) => {
  const addingRecord = !account;

  const [serviceProvider, updateServiceProvider] = React.useState(
    serviceProviderRecordFromAccount(account, defaultServiceProvider)
  );

  const saveAccount = React.useCallback(
    ({ sector, record }) => {
      const proc = account && account.id ? updateAccount : createAccount;

      return proc({ caseId, signature, sector, record });
    },
    [account, caseId, signature]
  );

  return (
    <FormStack>
      {!account && !defaultServiceProvider && (
        <ServiceProviderFields
          serviceProviders={serviceProviders}
          record={serviceProvider || {}}
          errors={{}}
          update={updateServiceProvider}
        />
      )}

      {!serviceProvider?.serviceProviderId && !serviceProvider.addingServiceProvider ? (
        <NoAccountFields updateTemplate={updateTemplate} />
      ) : serviceProvider?.customForm === "aig" ? (
        <AIGAccountFields
          saveAccount={saveAccount}
          properties={properties}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          uploadedFileInfo={uploadedFileInfo}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.customForm === "hastings-direct" ? (
        <HastingsDirectAccountFields
          saveAccount={saveAccount}
          properties={properties}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          uploadedFileInfo={uploadedFileInfo}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Banking ||
        serviceProvider?.companyType === CompanyType.BuildingSociety ? (
        <BankingAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
          askForDeceasedPostcode={serviceProvider?.customForm === "starling-bank"}
        />
      ) : serviceProvider?.companyType === CompanyType.CreditCards ? (
        <CreditCardsAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          uploadedFileInfo={uploadedFileInfo}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Energy ? (
        <EnergyAccountFields
          saveAccount={saveAccount}
          properties={properties}
          persons={persons}
          serviceProvider={serviceProvider}
          addingRecord={addingRecord}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          uploadedFileInfo={uploadedFileInfo}
          createDocumentAndUpload={createDocumentAndUpload}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Insurance ? (
        <InsuranceAccountFields
          saveAccount={saveAccount}
          properties={properties}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          uploadedFileInfo={uploadedFileInfo}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Investments ? (
        <InvestmentsAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          uploadedFileInfo={uploadedFileInfo}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Mobile ? (
        <MobileAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          uploadedFileInfo={uploadedFileInfo}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          initialRecord={
            !addingRecord
              ? undefined
              : {
                deceased: {},
                phoneNumber: form?.deceased?.contactNumber,
                responsible: {},
              }
          }
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Mortgage ? (
        <MortgageAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Pension ? (
        <PensionAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          uploadedFileInfo={uploadedFileInfo}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.SocialMedia ||
        serviceProvider?.companyType === CompanyType.Email ? (
        <SocialMediaOrEmailAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          uploadedFileInfo={uploadedFileInfo}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          initialRecord={
            !addingRecord
              ? undefined
              : {
                deceased: {},
                emailAddress: form?.deceased?.email,
                phoneNumber: form?.deceased?.contactNumber,
              }
          }
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType === CompanyType.Water ? (
        <WaterAccountFields
          saveAccount={saveAccount}
          properties={properties}
          persons={persons}
          serviceProvider={serviceProvider}
          addingRecord={addingRecord}
          setBusy={setBusy}
          onAccountAdded={onAccountUpdated}
          account={account}
          uploadedFileInfo={uploadedFileInfo}
          createDocumentAndUpload={createDocumentAndUpload}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : serviceProvider?.companyType ? (
        <DefaultAccountFields
          saveAccount={saveAccount}
          persons={persons}
          serviceProvider={serviceProvider}
          setBusy={setBusy}
          uploadedFileInfo={uploadedFileInfo}
          onAccountAdded={onAccountUpdated}
          account={account}
          updateTemplate={updateTemplate}
          saveRecord={saveRecord}
          savedRecord={savedRecord}
          askForDeceasedDetails={askForDeceasedDetails}
        />
      ) : (
        <NoAccountFields updateTemplate={updateTemplate} />
      )}
    </FormStack>
  );
};

type NoAccountFieldsProps = {
  updateTemplate?: (p: any) => void;
};

const NoAccountFields: React.FC<NoAccountFieldsProps> = ({ updateTemplate }) => {
  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({});
  }, [updateTemplate]);

  return null;
};
