import * as React from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";

import { useSectionViewEventTracker } from "@utils/analytics";
import { updateForm } from "@api/caseApi";
import { useDialog } from "../../../contexts/useDialog";
import { updateAdditionalHelp } from "@api/online-api";
import { Document } from "../../../types/Document";
import { ExtendedAccount } from "../../../types/ExtendedAccount";
import { NotifierFormData, YesNo } from "@customTypes/index";
import urlPaths from "../../../urlPaths";
import { updateArrayElement } from "@utils/Functions";
import { Section } from "../../../Sections";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { ServiceProvider } from "../AccountForm/CompanyNameInput";
import { Property } from "../AccountForm/PropertyFields";
import { Person } from "../AccountForm/ResponsibleFields";
import { AccountsList } from "./AccountsList";
import { AddAccount } from "./AddAccount";
import { AssetSearchDialog } from "./AssetSearchDialog";
import { EditAccount } from "./EditAccount";
import { deleteAccount } from "@api/caseApi";

interface State {
  readonly addAccountKey: number;
  readonly error?: string;
}

export type AccountsProps = {
  caseId: string;
  signature: string | null;
  serviceProviders: ReadonlyArray<ServiceProvider>;
  persons: ReadonlyArray<Person>;
  properties: ReadonlyArray<Property>;
  prepareUpdate: Function;
  updateSuccessful: Function;
  updateFailure: (error: Error) => void;
  form: NotifierFormData;
  busy: boolean;
  setBusy: (b: boolean) => void;
  continueWithoutChanges: (section: Section, doNotAdvance?: boolean) => void;
  remoteError: string | undefined;
  updateServiceProvider: (s: ServiceProvider) => void;
  updatePerson: (p: Person) => void;
  updateProperty: (p: Property) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  onSectionClick: (section: string) => void;
  updateTemplate: UpdateFormTemplateProc;
  removeRemoteError: () => void;
};

export const Accounts: React.FC<AccountsProps> = ({
  caseId,
  signature,
  serviceProviders,
  persons,
  properties,
  prepareUpdate,
  updateSuccessful,
  updateFailure,
  form,
  busy,
  setBusy,
  continueWithoutChanges,
  remoteError,
  updateServiceProvider,
  updatePerson,
  updateProperty,
  uploadedFileInfo,
  onSectionClick,
  updateTemplate,
  removeRemoteError,
}) => {
  useSectionViewEventTracker("Accounts");

  const navigate = useNavigate();

  const { dialogVisible, showDialog, hideDialog } = useDialog();

  const { accounts } = form;

  const startAddingAccount = React.useCallback(() => {
    navigate(urlPaths.form("accounts", "new"));
  }, [navigate]);

  const cancelAddingAccount = React.useCallback(() => {
    navigate(urlPaths.form("accounts"));
  }, [navigate]);

  const onEditAccount = React.useCallback(
    (account: { id?: string }) => {
      if (account.id) {
        navigate(urlPaths.form("accounts", account.id));
      }
    },
    [navigate]
  );

  const cancelEditingAccount = React.useCallback(() => {
    navigate(urlPaths.form("accounts"));
  }, [navigate]);

  const onAccountAdded = React.useCallback(
    (
      acc: ExtendedAccount,
      p?: {
        newServiceProvider?: ServiceProvider;
        newPerson?: Person;
        newProperty?: Property;
      }
    ) => {
      let futureForm = { ...form, accounts: [acc, ...(form.accounts || [])] };

      futureForm = prepareUpdate(Section.Accounts, futureForm);

      if (p?.newServiceProvider) {
        updateServiceProvider(p.newServiceProvider);
      }

      if (p?.newPerson) {
        updatePerson(p.newPerson);
      }

      if (p?.newProperty) {
        updateProperty(p.newProperty);
      }

      updateSuccessful(futureForm, Section.Accounts, true);
      navigate(urlPaths.form("accounts"));
    },
    [
      form,
      prepareUpdate,
      updateSuccessful,
      updateServiceProvider,
      updatePerson,
      updateProperty,
      navigate,
    ]
  );

  const onAccountUpdated = React.useCallback(
    (
      acc: ExtendedAccount,
      p?: {
        newServiceProvider?: ServiceProvider;
        newPerson?: Person;
        newProperty?: Property;
      }
    ) => {
      const accounts = form?.accounts || [];
      const index = accounts.findIndex((account) => acc.id === account.id);
      if (index < 0) {
        return;
      }

      let futureForm = { ...form, accounts: updateArrayElement(accounts, index, () => acc) };

      futureForm = prepareUpdate(Section.Accounts, futureForm);

      if (p?.newServiceProvider) {
        updateServiceProvider(p.newServiceProvider);
      }

      if (p?.newPerson) {
        updatePerson(p.newPerson);
      }

      if (p?.newProperty) {
        updateProperty(p.newProperty);
      }

      updateSuccessful(futureForm, Section.Accounts, true);
      navigate(urlPaths.form("accounts"));
    },
    [
      form,
      prepareUpdate,
      updateSuccessful,
      updateServiceProvider,
      updatePerson,
      updateProperty,
      navigate,
    ]
  );

  const onRemoveAccount = (idx: number) => {
    const account = form.accounts && form.accounts[idx];
    const newAccounts = form.accounts
      ? [...form.accounts.slice(0, idx), ...form.accounts.slice(idx + 1)]
      : undefined;

    let futureForm = { ...form, accounts: newAccounts };
    futureForm = prepareUpdate(Section.Accounts, futureForm);

    const promise = account?.id
      ? deleteAccount({
        caseId,
        signature,
        accountId: account.id,
      })
      : updateForm({ caseId, signature, form: futureForm });

    promise.then(() => {
      updateSuccessful(futureForm, Section.Accounts, true);
    }, updateFailure);
  };

  const onContinue = React.useCallback(() => {
    if (localStorage.getItem("missing-asset-search-dialog-shown") !== "true") {
      localStorage.setItem("missing-asset-search-dialog-shown", "true");
      showDialog();
      return;
    }
    continueWithoutChanges(Section.Accounts);
  }, [showDialog, continueWithoutChanges]);

  const findOutMore = () => {
    updateAdditionalHelp({
      caseId,
      signature,
      record: { requestMissingAssetSearch: YesNo.Yes },
    }).catch(() => {
      // ignore
    });
    continueWithoutChanges(Section.Accounts);
  };

  const noThanks = () => {
    continueWithoutChanges(Section.Accounts);
  };

  return (
    <Routes>
      <Route
        index
        element={
          <>
            <AssetSearchDialog
              visible={dialogVisible}
              hideDialog={hideDialog}
              noThanks={noThanks}
              findOutMore={findOutMore}
            />

            <WrappedAccountsList
              accounts={accounts || []}
              busy={busy}
              onRemoveAccount={onRemoveAccount}
              onContinue={onContinue}
              onSectionClick={onSectionClick}
              updateTemplate={updateTemplate}
              onAddAccount={startAddingAccount}
              onEditAccount={onEditAccount}
            />
          </>
        }
      />

      <Route
        path="new"
        element={
          <AddAccount
            caseId={caseId}
            signature={signature}
            serviceProviders={serviceProviders || []}
            properties={properties}
            persons={persons}
            busy={busy}
            setBusy={setBusy}
            onCancel={cancelAddingAccount}
            onAccountAdded={onAccountAdded}
            uploadedFileInfo={uploadedFileInfo}
            onSectionClick={onSectionClick}
            updateTemplate={updateTemplate}
            isFirstAccount={(accounts || []).length === 0}
            remoteError={remoteError}
            form={form}
          />
        }
      />

      <Route
        path=":id"
        element={
          <WrappedEditAccount
            caseId={caseId}
            signature={signature}
            accounts={accounts}
            serviceProviders={serviceProviders || []}
            properties={properties}
            persons={persons}
            busy={busy}
            setBusy={setBusy}
            onCancel={cancelEditingAccount}
            onAccountUpdated={onAccountUpdated}
            uploadedFileInfo={uploadedFileInfo}
            onSectionClick={onSectionClick}
            updateTemplate={updateTemplate}
            remoteError={remoteError}
          />
        }
      />

      <Route
        path="*"
        element={
          <Navigate
            to={
              !accounts || accounts.length === 0
                ? urlPaths.form("accounts", "new")
                : urlPaths.form("accounts")
            }
            replace
          />
        }
      />
    </Routes>
  );
};

const WrappedAccountsList = (props: any) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!props.accounts || props.accounts.length === 0) {
      navigate(urlPaths.form("accounts", "new"));
    }
  }, [props.accounts, navigate]);

  return <AccountsList {...props} />;
};

const WrappedEditAccount = (props: any) => {
  const { id } = useParams();

  const account = props.accounts?.find((account: any) => account.id === id);
  const serviceProvider = props.serviceProviders?.find((sp: any) => sp.id === account?.companyId);

  return <EditAccount {...props} account={account} serviceProvider={serviceProvider} />;
};

const validate = ({ accounts }: NotifierFormData): string | undefined =>
  !accounts || accounts.length === 0
    ? "Please provide details of each account to close."
    : undefined;

export const isComplete = (form: NotifierFormData): boolean => validate(form) === undefined;
