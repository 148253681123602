import * as React from "react";

import { Alert, Snackbar, Stack } from "@mui/material";

import { Document } from "../../../types/Document";
import { ExtendedAccount } from "../../../types/ExtendedAccount";
import { Header } from "../../../components/atoms/Header";
import { AccountForm } from "../AccountForm";
import { ServiceProvider } from "../AccountForm/CompanyNameInput";
import { Property } from "../AccountForm/PropertyFields";
import { Person } from "../AccountForm/ResponsibleFields";
import { UpdateFormTemplateProc } from "@templates/FormTemplate";
import { AccountDetails } from "@customTypes/index";
import { createDocumentAndUpload } from "@api/caseApi";

export type EditAccountProps = {
  readonly caseId: string;
  readonly signature: string | null;
  readonly serviceProviders: ReadonlyArray<ServiceProvider>;
  readonly serviceProvider?: ServiceProvider;
  readonly persons: ReadonlyArray<Person>;
  readonly properties: ReadonlyArray<Property>;
  readonly busy: boolean;
  readonly setBusy: (b: boolean) => void;
  readonly onAccountUpdated: (
    a: ExtendedAccount,
    p?: {
      newServiceProvider?: ServiceProvider;
      newPerson?: Person;
      newProperty?: Property;
    }
  ) => void;
  readonly onCancel?: () => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly onSectionClick: (section: string) => void;
  readonly updateTemplate: UpdateFormTemplateProc;
  readonly remoteError: any;
  readonly account: AccountDetails;
};

export const EditAccount: React.FunctionComponent<EditAccountProps> = ({
  caseId,
  signature,
  serviceProviders,
  serviceProvider,
  properties,
  persons,
  remoteError,
  busy,
  setBusy,
  onCancel,
  onAccountUpdated,
  uploadedFileInfo,
  updateTemplate,
  onSectionClick,
  account,
}) => {
  const updateTemplateDelegate = React.useCallback(
    ({ onNext }) => {
      updateTemplate({
        busy,
        currentSection: "accounts",
        nextLabel: "Update",
        saveLabel: "Cancel",
        onNext,
        onSave: onCancel,
        onSectionClick: onSectionClick,
      });
    },
    [busy, onSectionClick, updateTemplate, onCancel]
  );

  const removeRemoteError = () => {
    console.log("TODO");
  };

  return (
    <Stack rowGap={2}>
      <Header level={1}>{account.companyName} account</Header>

      <AccountForm
        caseId={caseId}
        signature={signature}
        account={account}
        serviceProviders={serviceProviders}
        serviceProvider={serviceProvider}
        persons={persons}
        properties={properties}
        setBusy={setBusy}
        onAccountUpdated={onAccountUpdated}
        uploadedFileInfo={uploadedFileInfo}
        createDocumentAndUpload={(...args) => createDocumentAndUpload(caseId, signature, ...args)}
        updateTemplate={updateTemplateDelegate}
      />

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteError}
        autoHideDuration={6000}
        onClose={removeRemoteError}
      >
        <Alert elevation={6} variant="filled" severity="error" onClose={removeRemoteError}>
          {remoteError}
        </Alert>
      </Snackbar>
    </Stack>
  );
};
