import * as React from "react";

import { Alert, Snackbar } from "@mui/material";

import { Document } from "../../../types/Document";
import { ExtendedAccount } from "../../../types/ExtendedAccount";
import {
  AccountDetails,
  AccountNextStep,
  AccountType,
  CompanyType,
  YesNo,
} from "@customTypes/index";
import { cleanedErrors } from "@utils/Functions";
import { BooleanInput } from "../../../components/atoms/BooleanInput";
import { SelectInput } from "../../../components/atoms/SelectInput";
import { TextInput } from "../../../components/atoms/TextInput";
import { AccountNextStepInput } from "../../../components/molecules/AccountNextStepInput";
import { FormField } from "../../../components/molecules/FormField";
import { ServiceProvider } from "./CompanyNameInput";
import { useOnChange, useOnValueChange, useUpdater } from "@utils/callbacks";
import {
  Errors as PropertyErrors,
  errorsOfAddressOnly as errorsOfProperty,
  Property,
  propertyFieldsFromRecordAddressOnly,
  PropertyFields,
  Record as PropertyFieldRecord,
  recordFromAccount as propertyRecordFromAccount,
} from "./PropertyFields";
import {
  Errors as ResponsibleErrors,
  errorsOf as errorsOfResponsible,
  Person,
  Record as ResponsibleRecord,
  recordFromAccount as responsibleRecordFromAccount,
  ResponsibleFields,
} from "./ResponsibleFields";
import { Record as ServiceProviderRecord } from "./ServiceProviderFields";
import {
  Errors as DeceasedErrors,
  errorsOf as errorsOfDeceased,
  Record as DeceasedRecord,
  DeceasedDetailsFields,
} from "./DeceasedDetailsFields";

export type Record = {
  readonly deceased?: DeceasedRecord;
  readonly accountNumber?: string;
  readonly holderNumber?: string;
  readonly accountType?: string;
  readonly accountTypeOther?: string;
  readonly vehicleMake?: string;
  readonly vehicleModel?: string;
  readonly vehicleRegistrationNumber?: string;
  readonly property?: PropertyFieldRecord;
  readonly jointClaim?: string;
};

export type Errors =
  | undefined
  | {
    readonly deceased?: DeceasedErrors;
    readonly accountNumber?: string;
    readonly holderNumber?: string;
    readonly accountType?: string;
    readonly accountTypeOther?: string;
    readonly vehicleMake?: string;
    readonly vehicleModel?: string;
    readonly vehicleRegistrationNumber?: string;
    readonly property?: PropertyErrors;
    readonly jointClaim?: string;
  };

export const errorsOf: (r: Record, askForDeceasedDetails: boolean) => Errors = (
  record,
  askForDeceasedDetails
) => {
  return cleanedErrors({
    deceased: !askForDeceasedDetails ? undefined : errorsOfDeceased(record.deceased, false),
    accountType: record?.accountType ? undefined : "required",
    accountTypeOther:
      record?.accountType !== "other" || record?.accountTypeOther ? undefined : "required",
    property: errorsOfProperty(record?.property),
  });
};

export const recordFromAccount = (account?: AccountDetails): Record => {
  if (!account) {
    return {
      deceased: {},
    };
  }

  return {
    deceased: {},
    accountNumber: account.accountNumber,
    holderNumber: account.holderNumber,
    accountType: account.accountType,
    accountTypeOther: account.accountTypeOther,
    vehicleMake: account.vehicleMake,
    vehicleModel: account.vehicleModel,
    vehicleRegistrationNumber: account.vehicleRegistrationNumber,
    property: propertyRecordFromAccount(account),
    jointClaim: account.jointClaim,
  };
};

export type HastingsDirectAccountFieldsProps = {
  readonly properties: ReadonlyArray<Property>;
  readonly persons: ReadonlyArray<Person>;
  readonly serviceProvider: ServiceProviderRecord;
  readonly account?: AccountDetails;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: { newServiceProvider?: ServiceProvider; newPerson?: Person }
  ) => void;
  readonly updateTemplate?: (p: any) => void;

  readonly saveAccount: (r: { sector: CompanyType; record: any }) => Promise<any>;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly askForDeceasedDetails?: boolean;
};

export const HastingsDirectAccountFields: React.FC<HastingsDirectAccountFieldsProps> = ({
  properties,
  persons,
  serviceProvider,
  account,
  setBusy,
  onAccountAdded,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails = false,
}) => {
  const [record, update] = React.useState(savedRecord || recordFromAccount(account));
  const [errors, setErrors] = React.useState({} as Errors);
  const [remoteErrors, setRemoteErrors] = React.useState(undefined as string | undefined);

  const updateDeceasedFields = useUpdater(update, "deceased");

  const onJointClaimValueChanged = React.useCallback(
    (jointClaim) => {
      update((s: any) => ({
        ...s,
        jointClaim: jointClaim ? YesNo.Yes : YesNo.No,
      }));
    },
    [update]
  );

  const updateProperty = useUpdater(update, "property");
  const updateAccountHolder = useUpdater(update, "responsible");
  const onAccountTypeValueChange = useOnValueChange(update, "accountType");
  const onChange = useOnChange(update);

  React.useEffect(() => {
    if (!updateTemplate) {
      return;
    }

    updateTemplate({
      // onNextLabel: "Continue",
      onNext: !serviceProvider?.companyType
        ? undefined
        : () => {
          const errors = errorsOf(record, askForDeceasedDetails);

          if (errors) {
            setErrors(errors);
            if (saveRecord) {
              saveRecord({ source: record });
            }
            return;
          }

          const responsible = {
            id: record.responsible?.responsibleId || undefined,
            details: record.responsible?.responsible || undefined,
            bankAccount: record.responsible?.responsibleBankAccount || undefined,
          };

          const data = {
            id: account?.id,
            serviceProvider: {
              id: serviceProvider.serviceProviderId,
              companyName: serviceProvider.customProviderName,
              companyType: serviceProvider.companyType,
            },
            deceased: askForDeceasedDetails ? record.deceased : undefined,
            accountNumber: record.accountNumber,
            accountType: record.accountType,
            accountTypeOther: record.accountTypeOther,
            ...propertyFieldsFromRecordAddressOnly(record.property),
            vehicleMake: record.vehicleMake,
            vehicleModel: record.vehicleModel,
            vehicleRegistrationNumber: record.vehicleRegistrationNumber,
            nextStep: AccountNextStep.RequestAccountBalance,
            // documentIds,
            holderNumber: record.holderNumber,
            jointClaim: record.jointClaim,
            responsible: {
              responsibleId: persons[0]?.id,
            },
          };

          if (saveRecord) {
            saveRecord({ source: record, target: data });
            return { response: {} };
          }

          setBusy(true);

          return saveAccount({
            sector: CompanyType.Insurance,
            record: data,
          }).then(
            (
              response: {
                data: {
                  account: ExtendedAccount;
                  newServiceProvider?: ServiceProvider;
                  newPerson?: Person;
                };
              } & { error: Error }
            ) => {
              setBusy(false);
              if (response.error) {
                setRemoteErrors(response.error.message);
                return;
              }
              if (response.data) {
                onAccountAdded(response.data.account, {
                  newServiceProvider: response.data.newServiceProvider,
                  newPerson: response.data.newPerson,
                });
                return;
              }
            },
            (err: Error) => {
              console.warn({ err });
              setBusy(false);
              setRemoteErrors("Operation failed. Please try again or contact customer support.");
            }
          );
        },
    });
  }, [updateTemplate, serviceProvider, account, onAccountAdded, record, setBusy, saveAccount]);

  return (
    <>
      {askForDeceasedDetails && (
        <DeceasedDetailsFields
          record={record.deceased}
          update={updateDeceasedFields}
          errors={errors?.deceased}
        />
      )}

      <FormField halfWidth label="Policy number">
        <TextInput
          name="accountNumber"
          value={record.accountNumber || ""}
          onChange={onChange}
          error={errors?.accountNumber}
          placeholder=""
        />
      </FormField>

      <FormField halfWidth label="Account number">
        <TextInput
          name="holderNumber"
          value={record.holderNumber || ""}
          onChange={onChange}
          error={errors?.holderNumber}
          placeholder=""
        />
      </FormField>

      <FormField halfWidth label="Policy type">
        <SelectInput
          name="accountType"
          value={record.accountType || ""}
          onValueChange={onAccountTypeValueChange}
          error={errors?.accountType}
          options={accountTypeOptions}
        />

        {record.accountType === AccountType.Other && (
          <TextInput
            name={"accountTypeOther"}
            value={record.accountTypeOther || ""}
            error={errors?.accountTypeOther}
            onChange={onChange}
          />
        )}
      </FormField>

      {(record.accountType === AccountType.Vehicle ||
        record.accountType === AccountType.BreakdownCover) && (
          <>
            <FormField halfWidth label="Vehicle make">
              <TextInput
                name="vehicleMake"
                value={record.vehicleMake || ""}
                onChange={onChange}
                error={errors?.vehicleMake}
              />
            </FormField>

            <FormField halfWidth label="Vehicle model">
              <TextInput
                name="vehicleModel"
                value={record.vehicleModel || ""}
                onChange={onChange}
                error={errors?.vehicleModel}
              />
            </FormField>

            <FormField halfWidth label="Vehicle registration number">
              <TextInput
                name="vehicleRegistrationNumber"
                value={record.vehicleRegistrationNumber || ""}
                onChange={onChange}
                error={errors?.vehicleRegistrationNumber}
              />
            </FormField>
          </>
        )}

      <PropertyFields
        serviceProvider={serviceProvider}
        addressOnly
        label="What is the address associated with this policy?"
        serviceProviderId={serviceProvider.serviceProviderId}
        persons={persons}
        properties={properties}
        record={record.property || {}}
        errors={errors?.property}
        update={updateProperty}
      />

      <FormField>
        <BooleanInput
          label="This is a joint policy"
          value={record.jointClaim === YesNo.Yes}
          onValueChange={onJointClaimValueChanged}
        />
      </FormField>

      <Snackbar
        sx={{ top: "58px" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!remoteErrors}
        autoHideDuration={6000}
        onClose={() => setRemoteErrors(undefined)}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity="error"
          onClose={() => setRemoteErrors(undefined)}
        >
          {remoteErrors}
        </Alert>
      </Snackbar>
    </>
  );
};

const accountTypeOptions = [
  { value: AccountType.Vehicle, label: "Vehicle" },
  { value: AccountType.Home, label: "Home" },
];
