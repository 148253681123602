import * as React from "react";

import { Document, UploadRecord } from "@src/types";
import { ExtendedAccount } from "@src/types/ExtendedAccount";
import { AccountDetails, CompanyType } from "@src/types";
import { ServiceProvider } from "./CompanyNameInput";
import { Property } from "./PropertyFields";
import { Person } from "./ResponsibleFields";
import { Record as ServiceProviderRecord } from "./ServiceProviderFields";
import { UtilitiesAccountFields } from "./UtilitiesAccountFields";

type EnergyAccountFieldsProps = {
  readonly properties: ReadonlyArray<Property>;
  readonly persons: ReadonlyArray<Person>;
  readonly serviceProvider: ServiceProviderRecord;
  readonly account?: AccountDetails;
  readonly addingRecord: boolean;
  readonly setBusy: (b: boolean) => void;
  readonly uploadedFileInfo: (id: string) => Promise<Document>;
  readonly createDocumentAndUpload: (
    file: File,
    filename?: string,
    tags?: string[]
  ) => Promise<UploadRecord>;

  readonly onAccountAdded: (
    a: ExtendedAccount,
    p?: {
      newServiceProvider?: ServiceProvider;
      newPerson?: Person;
      newProperty?: Property;
    }
  ) => void;
  readonly updateTemplate?: (p: any) => void;

  readonly saveAccount: (r: { sector: CompanyType; record: any }) => Promise<any>;
  readonly saveRecord?: (r: any) => void;
  readonly savedRecord?: any;
  readonly askForDeceasedDetails?: boolean;
};

export const EnergyAccountFields: React.FC<EnergyAccountFieldsProps> = ({
  properties,
  persons,
  serviceProvider,
  account,
  setBusy,
  uploadedFileInfo,
  createDocumentAndUpload,
  onAccountAdded,
  updateTemplate,
  saveAccount,
  saveRecord,
  savedRecord,
  askForDeceasedDetails,
}) => {
  return (
    <UtilitiesAccountFields
      properties={properties}
      persons={persons}
      serviceProvider={serviceProvider}
      setBusy={setBusy}
      onAccountAdded={onAccountAdded}
      account={account}
      uploadedFileInfo={uploadedFileInfo}
      createDocumentAndUpload={createDocumentAndUpload}
      version={"energy"}
      updateTemplate={updateTemplate}
      saveAccount={saveAccount}
      saveRecord={saveRecord}
      savedRecord={savedRecord}
      askForDeceasedDetails={askForDeceasedDetails}
    />
  );
};
