import * as React from "react";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { CompanyType, readableCompanyType } from "@customTypes/index";

export type ServiceProvider = {
  id: string;
  companyName: string;
  serviceProviderType: CompanyType;
  customForm?: string;
  slug?: string;
  deathCertificateNeeded?: boolean;
  proofOfAddressNeeded?: boolean;
  willNeeded?: boolean;
  grantOfRepresentationNeeded?: boolean;
  idDocumentNeeded?: boolean;
  faceSimilarityNeeded?: boolean;
};

export type CompanyNameInputProps = {
  serviceProviders: ReadonlyArray<ServiceProvider>;
  sectors?: ReadonlyArray<CompanyType>;
  serviceProviderId?: string;
  customProviderName?: string;
  setNewServiceProviderName: (name: string) => void;
  setServiceProvider: (id: string, type: CompanyType, customForm: string) => void;
  clear: () => void;
};

type ServiceProviderOptionType = {
  id?: string;
  companyName?: string;
  inputValue?: string;
  serviceProviderType?: string;
};

export const CompanyNameInput: React.FC<CompanyNameInputProps> = ({
  serviceProviders,
  sectors,
  serviceProviderId,
  customProviderName,
  setNewServiceProviderName,
  setServiceProvider,
  clear,
}) => {
  const [sectorServiceProviders, otherServiceProviders] = partition(serviceProviders, (sp) => {
    //@ts-ignore
    return !!sectors && sectors.includes(sp.serviceProviderType);
  });

  const options: Array<ServiceProviderOptionType> = sectorServiceProviders.slice().sort((a, b) => {
    return a.companyName.localeCompare(b.companyName);
  });

  const otherOptions: Array<ServiceProviderOptionType> = otherServiceProviders
    .slice()
    .sort((a, b) => {
      return a.companyName.localeCompare(b.companyName);
    });

  const optionsDict = Object.fromEntries(options.map((o) => [o.id, o]));

  const value = serviceProviderId
    ? optionsDict[serviceProviderId]
    : customProviderName
      ? { inputValue: customProviderName }
      : null;

  const autoCompleteOnChange = (_event: any, newValue: any) => {
    if (typeof newValue === "string") {
      setNewServiceProviderName(newValue);
    } else if (newValue && newValue.inputValue) {
      setNewServiceProviderName(newValue.inputValue);
    } else if (newValue) {
      setServiceProvider(newValue.id, newValue.serviceProviderType, newValue.customForm);
    } else {
      clear();
    }
  };

  const filterOptions = (options: any, params: any) => {
    const filtered = filter(options, params);

    const otherFiltered: any = params.inputValue.length < 2 ? [] : filter(otherOptions, params);

    if (params.inputValue.length >= 2 && filtered.length === 0 && otherFiltered.length === 0) {
      return [
        {
          companyName: params.inputValue,
          inputValue: params.inputValue,
        },
      ];
    }

    return filtered.concat(otherFiltered);
  };
  return (
    <Autocomplete
      value={value || ""}
      onChange={autoCompleteOnChange}
      filterOptions={filterOptions}
      blurOnSelect
      clearOnBlur
      handleHomeEndKeys
      selectOnFocus
      options={options}
      getOptionLabel={(option: any) => {
        if (typeof option === "string") return option;
        if (option.inputValue) return option.inputValue;
        return option.companyName || "";
      }}
      renderOption={(props: any, option: any) => {
        return (
          <li key={props?.key} {...props}>
            {option.inputValue ? (
              `Add "${option.inputValue}"`
            ) : sectors && !sectors.includes(option.serviceProviderType) ? (
              <i>
                {option.companyName} - {readableCompanyType(option.serviceProviderType)}
              </i>
            ) : (
              option.companyName
            )}
          </li>
        );
      }}
      noOptionsText="Search service provider..."
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          autoFocus
          variant="outlined"
          size="small"
          placeholder="Enter name or select a service provider"
        />
      )}
    />
  );
};

const filter = createFilterOptions();

const partition = (
  array: ReadonlyArray<ServiceProvider>,
  proc: (sp: ServiceProvider) => boolean
) => {
  const a = [];
  const b = [];

  for (const sp of array) {
    if (proc(sp)) {
      a.push(sp);
    } else {
      b.push(sp);
    }
  }

  return [a, b];
};
